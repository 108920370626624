<template>
    <loading-animation class="p-4" v-if="DataLoading"></loading-animation>
    <div v-else>
        <b-form :save-url="saveUrl" ref="addForm" @failure="formFailure" v-slot="{model, loading}"
                :save-params="{id : id}" :initial-data="details">
            <div class="fl-te-t mb-3">
                <h4>Staff Info</h4>
                <btn size="sm" class="" text="Save" type="submit" :loading="loading"
                     loading-text="Saving..."></btn>
            </div>
            <div class="fl-te-t">
                <div class="w-30p">
                    <staff-attachments :id="id.toString()"/>
                </div>
                <div style="width: 69%;">
                    <div class="card bs-4 p-0">
                        <h5 class="p-3 bb-2">Basic Details</h5>
                        <div class="row p-3">
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Name</h6>
                                <validated-input class="mb-0 field" name="Full Name" v-model="model.name"
                                                 :disabled="loading" :rules="{required:true}"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Gender</h6>
                                <validated-vue-select class="mb-0 field" name="Gender" :options="genderOptions"
                                                      v-model="model.gender"
                                                      :disabled="loading" :rules="{required:true}"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>E-Mail</h6>
                                <validated-input class="mb-0 field" type="email" v-model="model.email"
                                                 :disabled="loading" :rules="{required:true}"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Mobile</h6>
                                <validated-input class="mb-0 field" name="Mobile number" v-model="model.mobile_number"
                                                 :disabled="loading" :rules="{required:true}" type="text"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>DOB</h6>
                                <validated-date-picker class="mb-0 field" name="Dob" v-model="model.dob"
                                                       :rules="{required:true}" :disabled="loading"
                                                       :disabled-date="disabledBeforeTodayAndAfterAWeek"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Blood Group</h6>
                                <validated-ajax-vue-select class="mb-0 field" name="Country" :url="bloodGroupOptions"
                                                           :disabled="loading"
                                                           :rules="{required:true}" v-model="model.blood_group"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Marital Status</h6>
                                <validated-vue-select class="mb-0 field" name="Marital Status"
                                                      :options="maritalStatusOptions"
                                                      v-model="model.marital_status"
                                                      :disabled="loading" :rules="{required:true}"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Qualification</h6>
                                <validated-input class="mb-0 field" name="Qualification" v-model="model.qualification"
                                                 :disabled="loading" :rules="{required:true}"/>
                            </div>
                        </div>
                    </div>
                    <div class="card bs-4 p-0">
                        <h5 class="p-3 bb-2">Address</h5>
                        <div class="row p-3">
                            <div class="col-4 fl-te-c mb-3">
                                <h6>Country</h6>
                                <validated-ajax-vue-select class="mb-0 field" name="Country" :url="countryOptions"
                                                           :disabled="loading" @input="loadStateOptions"
                                                           :rules="{required:false}"
                                                           v-model="model.country"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>State</h6>
                                <validated-vue-select class="mb-0 field" :options="stateOptions"
                                                      :disabled="loading" @input="loadDistrictOptions"
                                                      :rules="{required:false}" v-model="model.state"/>
                            </div>
                            <div class="col-4 fl-te-c mb-3">
                                <h6>District</h6>
                                <validated-vue-select class="mb-0 field" name="District" :options="districtOptions"
                                                      :disabled="loading" :rules="{required:false}"
                                                      @input="checkIfNullValue"
                                                      v-model="model.district"/>
                            </div>
                            <div class="col-6 mb-3">
                                <h6>Present</h6>
                                <textarea-input class="mb-0" name="Present Address" v-model="model.present_address"
                                                :disabled="loading"
                                                :rules="{required:false}"/>
                            </div>
                            <div class="col-6 mb-3">
                                <h6>Permanent</h6>
                                <textarea-input class="mb-0" name="Permanent Address"
                                                v-model="model.permanent_address"
                                                :disabled="loading"
                                                :rules="{required:false}"/>
                            </div>
                        </div>
                    </div>
                    <div class="card bs-4 p-0">
                        <h5 class="p-3 bb-2">Salary Details</h5>
                        <div class="row p-3">
                            <div class="col-4 fl-te-c ">
                                <h6>Role</h6>
                                <validated-ajax-vue-select class="mb-0 field" name="Role" :url="roleOptions"
                                                           :rules="{required:false}"
                                                           v-model="model.role" :disabled="loading"/>
                            </div>
                            <div class="col-4 fl-te-c">
                                <h6>Department</h6>
                                <validated-ajax-vue-select class="mb-0 field" name="Department" :url="departmentOptions"
                                                           :rules="{required:false}"
                                                           v-model="model.department" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row p-3 mt-0">
                            <div class="col-4 fl-te-c">
                                <h6>Basic Salary</h6>
                                <validated-input class="mb-0 field" name="Basic Salary" v-model="model.basic_salary"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>
                            <div class="col-4 fl-te-c ">
                                <h6>Annual Salary</h6>
                                <validated-input class="mb-0 field" name="Annual Salary" v-model="model.annual_salary"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>

                        </div>
                    </div>
                    <div class="card bs-4 p-0">
                        <h5 class="p-3 bb-2">Account Details</h5>
                        <div class="row p-3">
                            <div class="col-4 fl-te-c ">
                                <h6>Bank Name</h6>
                                <validated-input class="mb-0 field" name="Bank" v-model="model.bank"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>
                            <div class="col-4 fl-te-c">
                                <h6>Branch Name</h6>
                                <validated-input class="mb-0 field" name="Branch Name" v-model="model.branch"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>
                        </div>
                        <div class="row p-3 mt-0">
                            <div class="col-4 fl-te-c">
                                <h6>Account No.</h6>
                                <validated-input class="mb-0 field" name="Account Number"
                                                 v-model="model.account_number"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>
                            <div class="col-4 fl-te-c ">
                                <h6>IFSC Code</h6>
                                <validated-input class="mb-0 field" name="Ifsc Code" v-model="model.ifsc_code"
                                                 :disabled="loading" :rules="{required:false}"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </b-form>

        <delete-modal ref="deleteImageModal" :url="staffProfileImageDeleteUrl" :params="deletingItem"
                      @response="deletedProfileImage">
            <p>You are about to delete the Image. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
        <modal title="Profile Image" ref="addProfileImageModal" width="30r">
            <b-form v-if="!loading" :save-url="addProfileImageUrl" @success="uploadSuccess" v-slot="{model, loading}"
                    :save-params="{id: id}">
                <validated-file-input placeholder="Image" v-model="model.image" :disabled="loading"
                                      :rules="{required:true}"/>
                <btn size="xs" text="Upload" :loading="loading" loading-text="Uploading..."></btn>
            </b-form>
        </modal>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import StaffAttachments from './components/staffAttachmentsComponent';

export default {
    name       : 'Catalog',
    components : { StaffAttachments },
    data () {
        return {
            DataLoading                : false,
            loading                    : false,
            id                         : this.$route.params.id,
            addProfileImageUrl         : urls.hrAdmin.staff.addProfileImage,
            staffProfileImageDeleteUrl : urls.hrAdmin.staff.staffProfileImageDelete,
            saveUrl                    : urls.hrAdmin.staff.staffDetailsSave,
            countryOptions             : urls.hrAdmin.staff.countryOptions,
            roleOptions                : urls.hrAdmin.staff.roleOptions,
            bloodGroupOptions          : urls.hrAdmin.staff.bloodGroupOptions,
            departmentOptions          : urls.hrAdmin.staff.departmentOptions,
            districtOptions            : [],
            stateOptions               : [],
            details                    : '',
            deletingItem               : null,
            genderOptions              : [
                { label : 'Male', value : 'M' },
                { label : 'Female', value : 'F' },
                { label : 'Other', value : 'O' }
            ],
            maritalStatusOptions : [
                { label : 'Single', value : 'Single' },
                { label : 'Married', value : 'Married' },
                { label : 'Widowed', value : 'Widowed' },
                { label : 'Divorced', value : 'Divorced' }
            ],
            rules : {
                contact_number : {
                    required : true,
                    max      : 10,
                    min      : 10
                },
                name : {
                    required : true
                },
                gender : {
                    required : true
                },
                role : {
                    required : true
                },
                email : {
                    required : true
                }
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        disabledBeforeTodayAndAfterAWeek (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate());
            return date > tomorrow;
        },
        formFailure () {
            this.$notify('Unable to save..Please try again later', 'Error', {
                type : 'danger'
            });
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.hrAdmin.staff.details, { user : that.id });
            const json = response.data;
            if (json.error === false) {
                that.details = json.data;
                if (that.details.country) {
                    await axios.form(urls.hrAdmin.staff.stateOptions, { country : that.details.country }).then(function (response) {
                        const json = response.data;
                        that.stateOptions = json.data;
                    });
                }
                if (that.details.state) {
                    await axios.form(urls.hrAdmin.staff.districtOptions, { state : that.details.state }).then(function (response) {
                        const json = response.data;
                        that.districtOptions = json.data;
                    });
                }
                that.DataLoading = false;
            } else {
                that.$notify('Details not found', 'Message', {
                    type : 'info'
                });
                that.DataLoading = false;
            }
        },
        loadStateOptions (item) {
            if (this.$refs.addForm.getModelValue('country') === null) {
                this.$refs.addForm.setModelValue('country', '');
            }
            this.$refs.addForm.setModelValue('state', '');
            this.$refs.addForm.setModelValue('district', '');
            this.stateOptions = [];
            this.districtOptions = [];
            const that = this;
            axios.form(urls.hrAdmin.staff.stateOptions, { country : item }).then(function (response) {
                const json = response.data;
                that.stateOptions = json.data;
                that.districtOptions = [];
            });
        },
        loadDistrictOptions (item) {
            if (this.$refs.addForm.getModelValue('state') === null) {
                this.$refs.addForm.setModelValue('state', '');
            }
            this.districtOptions = [];
            this.$refs.addForm.setModelValue('district', '');
            const that = this;
            axios.form(urls.hrAdmin.staff.districtOptions, { state : item }).then(function (response) {
                const json = response.data;
                that.districtOptions = json.data;
            });
        },
        checkIfNullValue (item) {
            if (this.$refs.addForm.getModelValue('district') === null) {
                this.$refs.addForm.setModelValue('district', '');
            }
        },
        uploadSuccess (response) {
            this.docList = response.data.image;
            const refs = this.$refs;
            refs.addProfileImageModal.close();
        },
        setImageDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteImageModal.show();
        },
        deletedProfileImage (response) {
            this.data = response.data.images.data;
            this.deletingItem = null;
            this.$refs.deleteImageModal.close();
        }
    }
};
</script>

<style scoped>
.field {
    width: 13rem;
}
</style>
