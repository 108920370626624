<template>
    <div>
        <div style="min-height: 130px; background-position: center center; background-size:cover;" :style="image"
             class="round-2 br-1 bs-1">
            <div class="btn-group p-2">
                <btn icon="fa fa-trash" @click="setDelete" color="danger" class="btn-xs"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name  : 'StaffDocs',
    props : {
        item : { type : String }
    },
    computed : {
        image () {
            return { backgroundImage : 'url(' + this.item + ')' };
        }
    },
    methods : {
        setDelete () {
            this.$emit('click', 'delete');
        }
    }

};
</script>

<style scoped>

</style>
