<template>
    <div class="card bs-5 p-0">
        <h5 class="p-3" style="height: 45px;">Uploads</h5>
        <loading-animation v-if="DataLoading"></loading-animation>
        <div v-else>
            <div>
                <div class="bgc-tertiary-dark p-3 h6" style="height: 45px; color: #6c757d;">Profile</div>
                <div class="row p-4">
                    <div class="card col-md-4 mt-0" v-if="profileImage.length!==0">
                        <div style="min-height: 130px; background-position: center center; background-size:cover;" :style="image"
                             class="round-2 br-1 bs-1">
                            <div class="btn-group p-2">
                                <btn icon="fa fa-pencil" @click.prevent="$refs.addProfileModal.show()" color="primary" class="btn-xs"/>
                            </div>
                        </div>
                    </div>

                    <div class="card col-md-4 mt-0" v-else>
                        <div style="min-height: 130px; background-position: center center; background-size:cover;"
                             class="round-2 br-1 bs-1 fl-eq-c p-4" @click="$refs.addProfileModal.show()">
                            <img src="https://imagga.com/static/images/upload.svg">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="bgc-tertiary-dark p-3 h6" style="height: 45px; color: #6c757d;">KYC Documents</div>
                <div class="row p-4">
                    <staff-docs class="card col-md-4 mt-0"
                                v-for="record in docList"
                                :key="record.id" :item="record.file" @click="setDocDelete(record)">
                    </staff-docs>

                    <div class="card col-md-4 mt-0">
                        <div style="min-height: 130px; background-position: center center; background-size:cover;"
                             class="round-2 br-1 bs-1 fl-eq-c p-4" @click="$refs.addDocModal.show()">
                            <img src="https://imagga.com/static/images/upload.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal no-close-on-backdrop title="Profile Image" ref="addProfileModal" width="30r">
            <b-form :save-url="addProfileUrl" ref="form" @success="formSuccess1" v-slot="{model, loading}"
                    :save-params="{user: id}">
                <validated-file-input name="File" placeholder="Profile Image" v-model="model.image" :disabled="loading"
                                      :rules="{required:false}"/>
                <btn size="xs" text="Upload" :loading="loading" loading-text="Uploading..."></btn>
            </b-form>
        </modal>

        <modal no-close-on-backdrop title="Upload Doc" ref="addDocModal" width="30r">
            <b-form v-if="!loading" :save-url="addDocUrl" @success="formSuccess" v-slot="{model, loading}"
                    :save-params="{user: id}">
                <validated-file-input name="File" placeholder="KYC Doc" v-model="model.file" :disabled="loading"
                                      :rules="{required:false}"/>
                <btn size="xs" text="Upload" :loading="loading" loading-text="Uploading..."></btn>
            </b-form>
        </modal>

        <delete-modal ref="deleteDocModal" :url="deleteDocUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the kyc document. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import StaffDocs from './StaffDocs';
import axios from 'secure-axios';

export default {
    name       : 'staffAttachments',
    props      : { id : { type : String } },
    components : { StaffDocs },
    computed   : {
        image () {
            return { backgroundImage : 'url(' + this.profileImage + ')' };
        }
    },
    data () {
        return {
            DataLoading   : '',
            profileImage  : '',
            docList       : '',
            addDocUrl     : urls.hrAdmin.staff.staffDocAdd,
            addProfileUrl : urls.hrAdmin.staff.addProfileImage,
            deleteDocUrl  : urls.hrAdmin.staff.staffDocDelete,
            familyOptions : urls.hrAdmin.familyOptions,
            loading       : false,
            deletingItem  : {
                id   : '',
                user : ''
            },
            editingItem : null
        };
    },
    beforeMount () {
        this.loadDocDetails();
    },
    methods : {
        async loadDocDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.hrAdmin.staff.staffDocList, { id : that.id });
            const imageResponse = await axios.form(urls.hrAdmin.staff.ProfileImage, { user : that.id });
            that.profileImage = imageResponse.data.data.image;
            const json = response.data;
            that.docList = json.data;
            that.DataLoading = false;
        },
        formSuccess (response) {
            this.docList = response.data.kyc.data;
            const refs = this.$refs;
            refs.addDocModal.close();
        },
        formSuccess1 (response) {
            this.profileImage = response.data.image.image;
            const refs = this.$refs;
            refs.addProfileModal.close();
        },
        setDocDelete (item) {
            this.deletingItem.user = this.id;
            this.deletingItem.id = item.id;
            this.$refs.deleteDocModal.show();
        },

        deleteComplete (response) {
            this.docList = response.data.kyc.data;
            this.deletingItem = null;
            this.$refs.deleteDocModal.close();
        }
    }
}
;
</script>

<style>
.bgc-tertiary-dark {
    background-color: #eeeeee;
}

</style>
